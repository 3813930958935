.home__creations {
  .items {
    @include flexbox;
    @include align-items(center);
    @include flex-flow(row wrap);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
  }
  .item:nth-child(1) {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }

  img:nth-of-type(1) {
    grid-column: 1 / 3;
    // grid-row: 1 / 2;
    height: 350px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  img:nth-of-type(2),
  img:nth-of-type(3) {
    height: 350px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@include breakpoint(980) {
  .home__creations .items {
    display: block;

    .item:nth-child(1) {
      margin-bottom: 2rem;
    }
  }
}

@include breakpoint(550) {
  .home__creations .item:nth-child(1) {
    display: block;
  }
  .home__creations img {
    height: auto !important;
  }
}
