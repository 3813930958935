.hikashop_product_page {
  // max-width: var(--container);
  margin: 4rem auto 8rem auto;
  padding: 0 1rem;
}

// =========================================================================== LEFT

.hikashop_product_left_part {
  //   width: 100%;
  .hikashop_product_main_image_subdiv {
    background-color: white;
    // border-radius: 7px;
    margin-bottom: 1rem !important;
    img {
      // border-radius: 7px;
      margin: 0 !important;
    }
  }
  .hikashop_product_main_image_thumb {
    height: auto !important;
  }
  #hikashop_small_image_div {
    // margin-top: -13px;
    text-align: left;
    img {
      max-width: 70px;
      max-height: 70px;
      // border-radius: 4px;
    }
    a {
      border: 2px solid transparent !important;
      // border-radius: 4px;
    }
    a.hikashop_active_thumbnail {
      border: 2px solid var(--color-1) !important;
    }
  }
}

.hika__infos {
  max-width: 980px;
  margin-top: 3rem;
  #hikashop_product_description_main {
    h3 {
      border-bottom: 1px solid var(--gray-4);
      padding-bottom: 0.5rem;
      margin-bottom: 1.5rem;
    }
  }

  #hikashop_product_custom_info_main {
    margin-top: 2rem;
    h4 {
      display: none;
    }
    label {
      margin: 0 0 0.5rem 0;
      font-weight: bold;
      font-size: 1.2rem;
      text-transform: unset;
    }
    td {
      display: block;
    }
  }
}

// =========================================================================== RIGHT

.hikashop_product_right_part {
  padding-left: 2rem;

  .hikashop_product_name_main {
    display: block;
    font-size: rem(25);
    line-height: 1;
  }

  .hikashop_product_code_main {
    font-size: rem(14);
    color: var(--color-1);
    font-weight: 500;
    &::before {
      content: "Référence : ";
      font-weight: normal;
    }
  }

  .hikashop_product_description_main {
    margin: 1rem 0 2rem 0;
  }

  .hikashop_product_price_full {
    display: block;
    margin: 1rem 0 0.5rem 0;
    font-size: rem(35);
    font-weight: bold;
  }
  .hikashop_product_price_per_unit {
    display: none;
  }
  .hikashop_product_discount_amount {
    // display: none;
  }
  .hikashop_product_price_before_discount {
    text-decoration: line-through;
    font-size: 1.3rem;
    font-weight: normal;
    margin-right: 7px;
    color: var(--color-1);
  }

  .btn.hikawishlist {
    margin-right: 1rem;
  }

  .btn--add-cart {
    padding: 15px 25px;
    width: auto;
    color: white;
    background-color: var(--color-1);
    // border: 1px solid transparent;
    opacity: 1 !important;
    &::after {
      margin-left: 7px;
    }
  }

  .hika__product__stock-delivery {
    margin-top: 2rem;
    text-transform: uppercase;

    font-weight: 300;
    font-size: 1rem;

    span {
      @include inline-flex;
      @include align-items(center);
      i {
        margin-right: 7px;
        color: var(--color-1);
        font-size: 1.5rem;
      }
    }
  }

  .hikashop_product_stock_count {
    margin-right: 1rem;
  }

  .hikashop_product_weight_main {
    display: none;
  }
}

@include breakpoint(992) {
  .hikashop_product_page {
    margin: 2rem auto 4rem auto;
  }
  .hikashop_product_left_part #hikashop_small_image_div img {
    max-width: 50px;
    max-height: 50px;
  }

  .hikashop_product_right_part {
    padding: 0;
    margin-top: 2rem;
  }
  .hikashop_product_right_part .hikashop_product_name_main {
    font-size: 1.2rem;
  }
  .hikashop_product_right_part .hikashop_product_code_main {
    font-size: 0.8rem;
  }
  .hikashop_product_right_part .hikashop_product_price_full {
    font-size: 1.6rem;
  }
  .hikashop_product_right_part .btn--add-cart {
    padding: 10px 20px;
  }
  .hikashop_product_right_part .hika__product__stock-delivery {
    font-size: 0.8rem;
  }
  .hikashop_product_right_part .hika__product__stock-delivery span i {
    font-size: 1.3rem;
  }
}
