.hika__category__menu {
  --category-menu-width: 380px;
  --category-header-height: 50px;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: -380px;
  width: var(--category-menu-width);
  height: 100vh;
  // max-height: calc(100vh - 100px);
  overflow: auto;
  // padding-bottom: 1rem;
  background: var(--background-light);
  // padding: 0 1rem;

  &.hika__category__menu--active {
    left: 0;
  }

  // ---------------------------------------- HEADER
  .menu__header {
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    border-bottom: 2px solid var(--color-1);
    height: var(--category-header-height);

    .menu__title {
      font-weight: 500;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    button {
      color: var(--color-1);
      font-size: 1.5rem;
    }
  }
  // ---------------------------------------- EO HEADER
  // ---------------------------------------- BODY
  .menu__body {
    position: relative;
    overflow: hidden;
    max-width: 380px;
    background-color: var(--background-light);

    .category__menu__main,
    .category--active {
      position: relative;
      transform: translatex(0);
      // height: calc(100vh - 50px);
      // overflow: auto;
    }

    .category__menu__main {
      // overflow: auto;
      // max-height: 100vh;
    }

    .category--active {
      position: absolute;
      width: 380px;
      z-index: 10;
      top: 0;
      left: 0;
      transition: transform 0.15s;
      // height: 100%;
    }

    ul {
      position: absolute;
      background-color: var(--background-light);
      width: 100%;
      // height: 100vh;
      // overflow: auto;

      top: 0;
      left: 0;
      transform: translatex(100%);
      padding: 0 1rem;
    }

    li {
      padding: 10px 0;

      &.all__products {
        @include flexbox;
        @include align-items(center);
        @include justify-content(space-between);
        font-size: 1.2rem;
        color: var(--color-1);
        font-weight: 500;
        border-bottom: 2px solid var(--color-1);
        margin-bottom: 0.5rem;
        padding: 4px 0;
      }

      // ------------------- title category
      a,
      span {
        position: relative;
        display: block;
        cursor: pointer;
        &:hover {
          color: var(--color-1);
          // color: white;
        }
        &.btn:hover {
          color: white;
        }
        &.btn:active {
          color: white;
        }
        i {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          font-size: 1.3rem;
        }
      }

      span {
      }
      span i {
      }

      // ------------------- EO title category

      .category__back {
        display: block;
        text-align: left;
        width: 100%;
        padding: 1rem 0;
        color: var(--color-1);
        i {
          margin-right: 7px;
        }
      }

      // ------------------- child

      // .menu__child {
      //   position: absolute;
      //   top: 0;
      //   width: var(--category-menu-width);
      //   height: 100%;
      //   left: -380px;
      //   background-color: var(--background-light);
      //   padding: 0 1rem;

      //   &.category--active {
      //     transition: left 0.15s;
      //     left: 0;
      //     z-index: 1001;
      //   }
      // }
    }
  }
  // ---------------------------------------- EO BODY
}

@include breakpoint(550) {
  .hika__category__menu {
    width: 100%;
    left: -100%;
  }
  .hika__category__menu .menu__body {
    max-width: 100%;
  }
}
