.hikashop_products {
  .hikashop_product {
    margin: 0 1rem 1rem 0;
  }
}

.page-liste-produits {
  .hika__category__top,
  .hika__category__sub {
    display: none !important;
  }
  .hikashop_products_listing_main {
    display: grid;
    grid-template-columns: minmax(240px, 280px) 1fr;
    padding: 0 1rem;
    gap: 0 2rem;
    align-items: flex-start;
    // max-width: var(--container);
    margin: 4rem auto 8rem auto;
  }
}

.hikashop_products_listing {
  .hk-row-fluid {
    @include flexbox;
  }
}

// ====================================================================== FILTER

// ====================================================================== EO FILTER

// ====================================================================== CATEGORY CONTENT
.hikashop_category_description {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(199, 105, 189);
    background: linear-gradient(
      45deg,
      rgba(199, 105, 189, 0.835171568627451) 0%,
      rgba(227, 222, 245, 0.5998774509803921) 50%
    );
  }
  img {
    display: block;
    width: 100%;
    height: 250px !important;
    object-fit: cover;
    object-position: center;
  }
  .hikashop_category_description_content {
    position: absolute;
    z-index: 10;
    width: 100%;
    max-width: 980px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .hika__category__name {
    // font-family: var(--font-titre);
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    text-shadow: 0 6px 12px rgba($color: #000000, $alpha: 0.4);
  }
  .hika__category__desc {
    font-size: 1.3rem;
    line-height: 1.7;
    color: var(--color-1);
  }
}
// ====================================================================== EO CATEGORY CONTENT

@include breakpoint(1080) {
  .hikashop_products_listing .hk-row-fluid {
    @include justify-content(center);
  }
  .page-liste-produits .hikashop_products_listing_main {
    grid-template-columns: 1fr;
  }
  .page-liste-produits .hikashop_products_listing {
    margin-top: 2rem;
  }
}

@include breakpoint(440) {
  .page-liste-produits .hikashop_products_listing_main {
    padding: 0;
  }
  .hikashop_products .hikashop_product {
    margin: 0 0.5rem 0.5rem 0;
  }
}
