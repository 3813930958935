.footer__avantages {
  background-color: var(--color-1);
  color: var(--color-1);
  @include flexbox;
  // @include align-items(center);
  @include justify-content(center);
  gap: 50px;
  padding: 2rem 0;

  .item {
    @include flexbox;
    @include align-items(center);
    font-size: 1.1rem;
    text-transform: uppercase;
  }

  i {
    margin-right: 7px;
    font-size: 3rem;
  }

  .item__title {
    display: block;
  }
  .item__desc {
    display: block;
    font-size: rem(14);
    font-weight: 300;
    text-transform: initial;
  }
}

.footer {
  position: relative;
  background-color: var(--color-1);
  color: white;
  // padding-top: 2rem;
  margin-left: var(--header-width);
  padding: 0 2rem;
}

.footer__container.container {
  display: grid;
  grid-template-columns: 1fr 200px 200px 1fr;
  grid-auto-flow: dense;
  gap: 50px;
  align-items: stretch;
  padding: 1rem;
  max-width: 100%;
}

// --------------------------------------------------------- items
.footer__item {
  i {
    color: var(--color-1);
  }
}

// --------------------------------------------------------- title
.footer__title,
.footer h3 {
  display: block;
  font-size: rem(20);
  font-family: var(--font-titre);
  text-transform: uppercase;
  color: var(--color-2);
  font-weight: bold;
  line-height: 1;
  // color: var(--color-1);
  margin-bottom: 0.7rem;
  &::after {
    content: "";
    display: block;
    width: 50px;
    height: 3px;
    background-color: var(--color-1);
  }
}

// --------------------------------------------------------- logo
.footer__item--logo {
  text-align: center;
  a {
    display: block;
    margin-bottom: 1rem;
  }
  img {
    max-height: 150px;
  }
  svg {
    height: 135px;
    width: auto;
  }

  p {
    font-size: rem(12);
  }
}

// --------------------------------------------------------- navigation

.moduletable {
}
.footer__item--menu {
  background-color: transparent !important;
  padding: 0 !important;

  //------------------------------------------------------ li
  li {
    // margin-left: rem(10);
    @include flexbox;
    @include align-items(center);

    &::before {
      // content: "-";
      // margin-right: 7px;
    }
  }

  //------------------------------------------------------ a

  span {
    cursor: pointer;
  }

  a {
    display: block;
    font-size: rem(16);
    font-weight: 500;
    transition: all 200ms;
    padding: 2px 0;
  }

  li.active a {
    color: var(--color-2);
  }
  //------------------------------------------------------ hover
  a:hover {
    color: var(--color-2);
  }
}

// --------------------------------------------------------- contact
.footer__item--contact {
  li {
    margin: rem(10) 0;
  }
}

.contact__link {
  display: flex;
  align-items: center;

  i {
    margin-right: rem(8);
    font-size: rem(26);
    text-align: center;
    width: 30px;
    line-height: 30px;
  }

  address,
  span {
    font-size: rem(16);
  }
}

// --------------------------------------------------------- A PROPOS
.footer__item--a-propos {
  p {
    font-size: 0.8rem;
  }
}

// --------------------------------------------------------- horaires

.footer__item--horaires {
  .horaires__day {
  }
}

// --------------------------------------------------------- Mentions
.footer__mentions {
  // border-top: 1px solid white;
  margin-top: 2rem;
  background-color: var(--color-1);
  text-align: center;
  color: white;
  padding: rem(10) 1rem;
  p {
    margin-bottom: 0;
    font-size: rem(14);
  }
}

// .footer__title::before {
//   display: inline-flex;
//   font-family: var(--font-awe);
//   content: "\f004";
// }

// ----------------------------------- Responsive
@include breakpoint(1180) {
}

@include breakpoint(980) {
  .footer__avantages {
    display: block;
    padding: 1rem 0;
    text-align: center;
    .item {
      display: block;
      margin: 1rem 0;
      i {
        font-size: 2rem;
      }
    }
  }

  .footer__container.container {
    display: block;
    text-align: center;
    padding: 2rem 1rem;
  }
  .footer__title::after,
  .footer h3::after {
    margin: auto;
  }
  .footer__item {
    margin: 2rem 0;
  }
  .footer__item--menu li {
    @include justify-content(center);
  }
  .contact__link {
    display: block;
  }
}
