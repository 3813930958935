.home-pieces-detachees {
  text-align: center;
  background-image: url("../../../images/bg-pieces-detachees.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 3rem 1rem;

  h2 {
    margin-bottom: 1.8rem;
  }

  .title {
    font-size: clamp(1.4rem, 2vw, 2rem);
  }

  .steps {
    @include flexbox;
    @include flex-flow(row wrap);
    @include justify-content(center);
    gap: 2rem;
    margin: 4rem 0;
  }

  .step {
    max-width: 250px;
  }

  .step__count {
    display: block;
    margin: auto;
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 2rem;
    font-weight: bold;
    background-color: var(--color-1);
    color: white;
    border-radius: 50px;
  }
  .step__title {
    display: block;
    margin-top: 0.8rem;
    font-size: 1.3rem;
  }
}
