.page-home {
  .fox-container {
    background-color: var(--gray-3);
  }
  .fox-form {
    padding: var(--padding-section) 0;
    margin: auto;
    max-width: 1200px;
    width: 90%;
  }
  #nous-contacter h2::after {
    margin: auto;
  }
}
// ================================================================= RESPONSIVE

@include breakpoint(1700) {
}

@include breakpoint(1600) {
}

@include breakpoint(1440) {
}

@include breakpoint(1380) {
}

@include breakpoint(1180) {
  .page-home .fox-form {
  }
}

@include breakpoint(980) {
}

@include breakpoint(780) {
}

@include breakpoint(550) {
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}
