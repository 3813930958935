header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
}

// .header {
//   position: relative;
//   top: 0;
//   left: 0;
//   width: 100%;
//   z-index: 100;
//   // background: var(--color-1);
//   background-color: white;
//   // background: var(--background-linear);
//   // color: white;
//   padding: 0 4rem;
//   border-bottom: 1px solid var(--color-1);
// }

// .header__container {
//   @include flexbox;
//   @include align-items(center);
//   @include justify-content(space-between);
//   gap: 20px;
//   height: 100px;
// }

// =============================================== HEADER TOP

.header__top {
  padding: 1rem 2.5rem;
  @include flexbox;
  @include align-items(center);
  @include flex-flow(row wrap);
  @include justify-content(space-between);
  display: grid;
  grid-template-columns: minmax(180px, 1fr) 150px;
  background-color: var(--color-1);
  color: white;
}

// =============================================== EO HEADER TOP

// =============================================== MENU VERTICAL

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 330px;
  height: 100%;
  background-color: white;
  border-right: 1px solid var(--gray-4);
}

.header__container {
  // @include flexbox;
  // @include align-items(center);
  // @include justify-content(space-between);
  // @include flex-flow(column wrap);
  // gap: 20px;
  // height: 100px;
}

// =============================================== EO MENU VERTICAL

// ------------------------ HEADER FIXED
// .header {
//   position: fixed;
//   z-index: 1001;
//   width: 100%;
//   top: 0;
//   padding: 0 1rem;
//   left: 0;
//   // color: white;
//   transition: all 0.25s;
//   height: 100px;
// }

// .header--fixed {
//   background-color: white;
//   box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.16);
//   color: initial;
// }

// .header--hidden {
//   transform: translateY(-130px);
// }
// ------------------------ EO HEADER FIXED

// ------------------------ SEARCH

.header__search {
  position: relative;
  @include flexbox;
  @include align-items(center);
  @include justify-content();
  background-color: var(--color-1);
  // padding-right: 10px;
  border-radius: 4px;
  // min-width: 600px;

  input,
  input:hover,
  input:active {
    border: 0;
  }

  button {
    display: block;
    font-size: 2rem;
    color: var(--color-2);
    background: white;
    padding: 0 10px;
    border-radius: 4px;
    margin-right: 10px;
  }

  .search__submit {
  }

  .search__clear {
    display: none;
  }
}

.header__search__result {
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  background: white;
  width: 100%;
  max-height: 400px;
  overflow: auto;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: var(--color-1);
  border-radius: 0 0 4px 4px;
  color: var(--color-1);
  padding: 0 1rem;

  .item {
    border-bottom: 1px solid var(--color-1);
  }

  .item a {
    @include flexbox;
    @include align-items(center);
    display: grid;
    grid-template-columns: 50px 1fr 150px;
    gap: 15px;
    padding: 5px 0;
    &:hover {
      color: var(--color-2);
    }
  }

  .search_result_img {
    @include flexbox;
    img {
      max-width: 50px;
      max-height: 50px;
    }
  }

  .search_result_price {
    text-align: right;
  }
}

// ------------------------ EO SEARCH

// ------------------------ Logo
.header__logo {
  // @include flexbox;
  // @include align-items(center);
  text-align: center;
  margin-top: 1rem;

  svg {
    // height: 80px;
    // width: auto;
  }
  img {
    // position: absolute;
    // top: 0;
    // max-height: 100px;
  }
}

// .header--fixed .header__logo img {
//   max-height: 100px;
// }

// ------------------------ Sociaux

.header__social {
  // @include flexbox;
  // @include align-items(flex-end);
  // color: white;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;

  .social__title {
    display: block;
    text-transform: uppercase;
    font-size: rem(14);
    line-height: 1;
  }
}

.header__phone {
  display: block;
  text-align: center;
  padding: 0.5rem;
  // margin-right: 2rem;
  font-size: clamp(1.2rem, 2vw, 2rem);
  // line-height: 30px;
  background-color: var(--color-1);
  color: white;

  .phone__number {
    font-weight: 500;
    // font-size: rem(26);
    // height: 30px;
  }
  i {
    margin-right: rem(7);
  }
}

.header__reseaux {
  // display: none;
  color: var(--color-1);
  font-weight: bold;
  margin-bottom: 1rem;
  text-transform: uppercase;

  a {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    font-size: clamp(0.8rem, 1vw, 1rem);
  }
  i {
    font-size: 2rem;
    margin-right: rem(7);
  }
}

// ------------------------ HEADER RIGHT (mon compte / panier)

.header__right {
  @include flexbox;
  @include justify-content(flex-end);
  // color: var(--color-1);
  gap: 1rem;

  a,
  span {
    // @include flexbox;
    // @include flex-flow(column wrap);
    // gap: 5px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1rem;
  }
  svg,
  i,
  .header__compte a::before,
  .hikashop_small_cart_checkout_link::before {
    display: block;
    margin: auto;
    // height: 24px;
    font-size: 2rem;
    fill: white;
    text-align: center;
    color: var(--color-2);
  }
  .header__compte a::before {
    @include icon(" \f2bd");
  }
  .hikashop_small_cart_checkout_link::before {
    @include icon(" \f218");
  }
}

// ------------------------ EO HEADER RIGHT

@include breakpoint(1650) {
  .header {
    // padding: 0 2rem;
  }
  .header__search {
    // min-width: 500px;
  }
}

@include breakpoint(1440) {
  .header__container {
    gap: 10px;
  }
  .menu__main li.item-101 {
    display: none;
  }
}

@include breakpoint(1280) {
  // ======================== VERTICAL
  .header {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: white;
    border-right: none;
    padding: 0 1rem;
    border-bottom: 1px solid var(--gray-4);
  }

  .header__container {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-flow(row wrap);
  }

  .header__logo {
    margin: 0;
    img {
      padding: 0.5rem 0;
      max-height: 80px;
    }
  }

  .header__social {
    position: relative;
    width: auto;
    @include flexbox;
    @include align-items(center);
    gap: 1rem;
  }

  .header__reseaux {
    margin: 0;
  }

  .header__phone {
    background-color: initial;
    color: var(--color-1);
  }

  // ======================== EO VERTICAL
  .header__menu {
    order: 3;
    text-align: right;
  }
  .header__reseaux {
    // display: none;
  }
}

@include breakpoint(980) {
  :root {
    --header-height: 80px;
  }

  .header__search {
    // position: absolute;
    // z-index: 101;
    // left: 0;
    // top: 100%;
    // width: 100%;
    // min-width: initial;
    // height: 70px;
  }

  .header__top {
    padding: 0.5rem;
  }
}

@include breakpoint(650) {
  .header {
    padding: 0 1rem;
    height: 70px;
  }
  .header__container {
    // height: 80px;
  }
  .header__logo svg,
  .header__logo img {
    height: 70px;
  }
  .header__top {
    grid-template-columns: minmax(180px, 1fr) 100px;
  }
  .header__search,
  .header__search input {
    height: 40px !important;
  }
  .header__right svg,
  .header__right i,
  .header__right .header__compte a::before,
  .header__right .hikashop_small_cart_checkout_link::before {
    font-size: 1.5rem;
    height: 28px;
  }
  .header__social {
    justify-content: center;
    text-align: center;
  }
  .header__social .social__title {
    margin-bottom: 0;
  }
  .header__phone {
    margin-right: 0;
  }
  .header__phone .phone__number {
    font-size: rem(16);
  }
  .header__reseaux {
    display: none;
  }
  .header__menu {
    text-align: center;
  }
  .header__right {
    gap: 8px;
  }
  .header__right svg {
    transform: scale(0.8);
  }
  .header__right a,
  .header__right span {
    font-size: 0.7rem;
  }
  .header__search button {
    height: 38px;
    width: 38px;
    text-align: center;
    font-size: 1.3rem;
  }

  .header__search__result .item_ a {
    grid-template-columns: 1fr 120px;
    gap: 10px;
    font-size: 0.85rem;
    .search_result_img {
      display: none;
    }
  }
}

@include breakpoint(440) {
  .header {
    padding: 0 8px;
  }
  .header__container {
    gap: 5px;
  }
}
