html {
  // scroll-behavior: smooth; / Remplacé par le smooth JS compatible IOS
  // height: 4000px;
}

body {
  word-break: break-word;
  text-rendering: optimizeLegibility;
  // color: var(--color-1);
  background-color: var(--background-light);
}

.body--active {
  overflow: hidden !important;
  height: 100vh;
}

.overlay {
  position: fixed;
  top: 0%;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: background 0.15s;
}

.body--active {
  .overlay {
    z-index: 1000;
    background-color: rgba($color: #000000, $alpha: 0.45);
  }
}

#wrapper {
  position: relative;
  // width: 100%;
  margin: auto;
  min-height: 100vh;
  // overflow: hidden;
}

.main {
  --full-width: 80px;
  // margin-top: 100px;
  // padding: 0 var(--full-width);
  margin-left: var(--header-width);
}

#maincontent {
  margin: 3rem 0 5rem 0;
}

.full-width {
  width: calc(100% + var(--full-width) * 2);
  margin-left: calc(-1 * var(--full-width));
}

.container {
  position: relative;
  max-width: rem(1180);
  margin: auto;
  padding: 0 1rem;
}

.content {
  max-width: 980px;
  margin: auto;
  padding: 0 1rem;
}

.section {
  padding: var(--padding-section) 0;
}

@include breakpoint(1440) {
  .main {
    --full-width: 14px;
  }
}

@include breakpoint(980) {
  .main {
    --full-width: 12px;
    // margin-top: 70px;
  }
}

@include breakpoint(650) {
  .main {
    // margin-top: 50px;
  }
}

@include breakpoint(550) {
  .main {
    --full-width: 8px;
  }
}
