// ------------------------------------------------ spinner / loading

#hikashop_checkout_cart_1_2 {
  .hikashop_checkout_loading_elem,
  .hikashop_checkout_loading_spinner {
    display: none !important;
  }
}
// -------- suppression loader dans btn
.hikashop_checkout_buttons {
  .next_button_disabled::after {
    display: none;
  }
}
// --------- page liste
.filter_refresh_div.hikashop_checkout_loading,
  // --------- page checkout
  #hikashop_checkout_cart_1_0.hikashop_checkout_loading,
  .hikashop_checkout_page.hikashop_checkout_loading,
  .hikashop_checkout_login.hikashop_checkout_loading,
  .hikashop_checkout_address.hikashop_checkout_loading,
  // --------- page espace client
  #hikashop_user_addresses_default.hikashop_checkout_loading,
  #hikashop_user_addresses_show.hikashop_checkout_loading {
  > .hikashop_checkout_loading_elem {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    z-index: 999 !important;
    background-color: black;
    opacity: 0.5 !important;
    filter: none !important;
  }
  > .hikashop_checkout_loading_spinner {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    margin-left: -25px;
    transform: translateY(-50%);
    background-image: none !important;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid var(--color-1);
    border-right: 3px solid transparent;
    animation: rotation 0.5s linear infinite;
  }
  > .hikashop_checkout_loading_spinner.small_spinner {
    background-image: none !important;
  }
}

// ============================================================= SMALL SPINNER
//   ------------------------------- cart
.hikashop_cart.hikashop_checkout_loading {
  > .hikashop_checkout_loading_elem {
    background-color: transparent !important;
  }
  > .hikashop_checkout_loading_spinner.small_spinner {
    background-image: none !important;
    position: absolute !important;
    right: -20px !important;
    top: initial !important;
    left: initial !important;
    bottom: 20px !important;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    border-top: 2px solid var(--color-1);
    border-right: 2px solid transparent;
    animation: rotation 0.5s linear infinite;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@include breakpoint(550) {
  //   ------------------------------- cart
  .hikashop_cart.hikashop_checkout_loading {
    > .hikashop_checkout_loading_spinner.small_spinner {
      right: 0 !important;
      top: 0 !important;
      right: 0 !important;
      bottom: initial !important;
    }
  }
}
