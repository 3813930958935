.hika__popup {
  // display: none;
  position: fixed;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  left: var(--header-width);
  margin-left: 1rem;
  bottom: 1rem;
  max-width: 470px;
  background-color: white;
  padding: 1rem;
  @include shadow-1;
  border-radius: 8px;

  &.active {
    opacity: 1;
    z-index: 1000;
    visibility: visible;
  }

  i {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
    font-size: 1.4rem;
    color: var(--color-1);
  }

  .title {
    display: block;
    color: var(--color-1);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
}

.hika__popup__wrapper {
  //   padding: 1rem 2rem 1rem 2rem;
  //   border-radius: 7px;
  //   padding: 1rem;
  //   max-width: 480px;

  .hikashop_products_listing_main {
    display: block !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .hikashop_product {
    width: initial !important;
    border: 0;
    margin: 0 !important;
  }

  .hikashop_product .card {
    height: unset;
    padding: unset;
    @include flexbox;
    @include align-items(center);
    @include flex-flow(row wrap);
    @include justify-content(space-between);
    display: grid;
    grid-template-columns: 50px 250px 150px;
    // border-bottom: 1px solid var(--gray-3);
    // gap: 10px;

    // max-width: 475px;
  }
  .hikashop_product .card__category {
    display: none;
  }
  .hikashop_product .card__img {
    max-width: 55px;
    margin: 0;
  }
  .hikashop_product_image_subdiv {
    width: initial !important;
  }
  .hikashop_product .card__name {
    font-size: 0.9rem;
    font-weight: unset;
    padding: 0 8px;
    -webkit-line-clamp: 1;
  }

  .hikashop_product .card__code {
    display: none;
  }
  .hikashop_product .card__price__cart {
    position: relative;
    width: initial;
    bottom: unset;
    right: unset;
    padding: unset;
    text-align: unset;
  }
  .hikashop_product .card__price .hikashop_product_price_before_discount {
    font-size: 0.8rem;
  }
  .hikashop_product .card__price {
    font-size: 1rem;
    text-align: right;
  }
  .btn {
    margin: 0%;
    font-size: 0.8rem;
    display: none;
    &::after {
      content: none;
    }
  }
}

@include breakpoint(550) {
  .hika__popup {
    display: none;
    padding: 2rem 1rem 1rem 1rem;
    margin: auto;
  }

  .hika__popup__wrapper .hikashop_product:last-child {
    display: none;
  }
  .hika__popup__wrapper .hikashop_product .card {
    display: block;
  }

  .hika__popup__wrapper .hikashop_products .hk-row-fluid {
    display: block !important;
  }
}
