/*
************* BTN PRIMARY
* ===================================
*/

.btn {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  outline: 0 !important;
  user-select: none;
  white-space: nowrap;
  touch-action: manipulation;
  border: none;
  color: white;
  height: initial;
  overflow: hidden;
  background-color: var(--color-1);
  // @include shadow-1;
  font-size: rem(20);
  border-radius: 7px;
  line-height: 1;
  font-weight: 500;
  // text-transform: lowercase;
  padding: 12px 24px;
  // transition: background 200ms, color 200ms;
  // margin-top: 2rem;

  i {
    margin-right: 7px;
  }

  &::after {
    content: "";
    position: absolute;
    transform: skewX(-30deg);
    z-index: -1;
    right: -15%;
    top: 0;
    width: 60%;
    height: 100%;
    background-color: var(--color-2);
    transition: all 0.15s;
  }

  &:hover {
    // background-color: rgba($color: var(--color-1), $alpha: 0.8);
    // background-color: transparent;
    // color: var(--color-1);
    // transition: all 100ms;
  }

  &:hover::after {
    width: 0%;
  }

  &:active,
  &:focus {
    background-color: var(--color-1);
    color: white;
  }
}

input.btn {
  height: initial !important;
}

button {
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: 0 !important;
  user-select: none;
  white-space: nowrap;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  touch-action: manipulation;
  font-family: var(--font-normal);
}

/*
************* BTN SECONDARY
* ===================================
*/

.btn--2,
.btn--3 {
  &::after {
    content: none;
  }
}

.btn--2 {
  background-color: var(--color-2);
}

.btn--outlined {
  background-color: transparent;
  color: var(--color-1);
  border: 1px solid var(--color-1);

  &:hover {
    // background-color: rgba($color: var(--color-1), $alpha: 1);
    // transition: all 200ms;
    // color: white;
    // border: 3px solid transparent;
  }

  &:active,
  &:focus,
  &.active {
    // background-color: var(--color-1);
    // color: white;
  }
}

/*
************* BTN CUSTOM
* ===================================
*/

.btn--custom {
  color: var(--color-1);
  background-color: transparent;
  padding: 0 !important;
  font-size: 1.1rem;
  font-weight: 300;
  &::after {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 50%;
    height: 2px;
    background-color: var(--color-2);
    transition: width 0.2s;
  }
  &:hover {
    border-color: transparent;
  }
  &:hover::after {
    width: 100%;
  }
}

.btn--small {
  padding: 4px 8px;
  font-size: 0.8rem;
}

.btn--submit {
  width: 100%;
}

.btn--success,
.btn--success:hover,
.btn--success:active,
.btn--success:focus {
  background-color: var(--success);
  border-color: var(--success);
}

.btn--danger,
.btn--danger:hover,
.btn--danger:active,
.btn--danger:focus,
.hika_j2 .btn-danger:hover,
.hikabtn-danger:hover,
.hika_j2 .btn-danger,
.hikabtn-danger {
  background-color: var(--error);
  border-color: var(--error);
}

/*
************* DISABLED
* ===================================
*/
.btn[disabled] {
  cursor: default;
  opacity: 0.1;
  &:hover,
  &:active,
  &:focus {
    background-color: var(--color-1);
    color: var(--color-light);
  }
}

/*
************* BTN TOP
* ===================================
*/

.btn__top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  transform: translateY(150px);
  z-index: 1000;

  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
  cursor: pointer;
  .btn__top__link {
    // background-color: $gray-6;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--color-1);
    color: white;
    box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.55);
    // border: 3px solid var(--color-light);
    transition: all 0.2s;

    i {
      font-size: 2rem;
    }
  }
}
.btn__top--active {
  transform: translateY(0);
  opacity: 1;
}

@include breakpoint(550) {
  .btn {
    padding: 8px 12px;
    font-size: rem(16);
  }
  .btn__top .btn__top__link {
    width: 50px;
    height: 50px;
  }
  .btn__top .btn__top__link i {
    font-size: 1.5rem;
  }
}
