// ========================================= CATEGORY

.hikam_breadcrumb_explorer {
  margin: 1rem 0;
}

.hikam_category_explorer {
  max-height: 250px;
  top: 54px !important;
  overflow: auto !important;
  border: 2px solid var(--color-2) !important;
}

// .hikam_category_explorer,
// .namebox-popup {
//   @include flexbox;
//   .oTreeNode {
//     padding: 8px 0;
//     margin-left: 5px;
//     border-bottom: 1px solid var(--color-1);
//   }
//   img {
//     display: none !important;
//   }
//   a {
//     @include inline-flex;
//     @include align-items(center);
//     font-size: 1.1rem !important;
//     text-decoration: none !important;
//     &:hover {
//       text-decoration: none !important;
//     }
//     &::before {
//       @include icon("\f358");
//       font-size: 1.4rem;
//       margin-right: 4px;
//       color: var(--color-1);
//       // display: block;
//       // width: 20px;
//       // height: 20px;
//       // line-height: 20px;
//       // text-align: center;
//       // background-color: var(--color-2);

//       // border-radius: 4px;
//     }
//   }
//   .clip {
//     margin-left: 1rem;
//   }
//   .e1,
//   .e2,
//   .e3,
//   .e4 {
//     margin-left: 0;
//   }

//   a.node,
//   a.nodeSel {
//     background-color: transparent;
//     &::before {
//       content: none;
//     }
//   }
// }

// ========================================= EO CATEGORY

.admin--category {
  .hikam_breadcrumb_explorer .hikam_category_explorer {
    //  display: none;
  }
  .hikam_breadcrumb {
    background-color: white;
    padding: 1rem;
    margin-bottom: 2rem;
  }
  .breadcrumb_sep {
    margin: 0 4px;
  }
}

//  ================================================  DETAILS

#hikamarket_categories_form {
  .hikam_blocks {
    width: 100%;
    max-width: 600px;
    display: block;
    tbody,
    tr,
    td {
      display: block;
      width: 100%;
    }

    .hikam_block_l {
      margin: 2rem 0 1rem 0;
    }
    // ------------- télécharger une image
    #hikamarket_category_image_uploadpopup {
      &::before {
        content: "Télécharger une image";
      }
    }
    // ------------- parcourir les images
    #hikamarket_category_image_addpopup {
      display: none;
    }

    #data_category_category_parent_id_text {
      cursor: pointer;
    }
  }
  .hikamarket_category_metadescription,
  .hikamarket_category_keywords,
  .hikamarket_category_pagetitle,
  .hikamarket_category_alias,
  .hikamarket_category_acl {
    display: none;
  }
}

//  ================================================  EO DETAILS
