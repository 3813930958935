.page-home {
  .moduletable {
    // background-color: var(--background-light-2);
    padding: var(--padding-section) 0;
  }
  .hikashop_products {
    .hk-row-fluid {
      @include justify-content(center);
    }
  }
}
