// ------ Module

.hikashop_categories_listing_main {
  --category-height: 380px;

  //   -------------------- container
  .hk-row-fluid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    &::before,
    &::after {
      content: none;
    }
  }

  //   -------------------- category

  .hikashop_category {
    position: relative;
    padding: 0 !important;
    margin: 0 !important;
    height: var(--category-height);
    width: 100% !important;
    .hikashop_category_image {
      position: relative;
      height: var(--category-height) !important;

      &::before {
        display: none;
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.4);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .hikashop_category_name {
      position: absolute;
      width: 100%;
      background: rgba($color: #c769bd, $alpha: 0.7);
      left: 0;
      text-align: left !important;
      z-index: 10;
      bottom: 0;
      padding: 5px 0 5px 15px;
      color: white;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.1rem;
      text-shadow: 0 2px 4px rgba($color: #000000, $alpha: 1);
      // text-shadow: var(--shadow-text);
    }
  }
}

@include breakpoint(1280) {
  .hikashop_categories_listing_main {
    .hk-row-fluid {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }
}

@include breakpoint(550) {
  .hikashop_categories_listing_main {
    --category-height: 250px;
    .hk-row-fluid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
    .hikashop_category {
      .hikashop_category_name {
        font-size: 0.9rem;
      }
    }
  }
}
