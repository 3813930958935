// ==================================================== SEARCH
.page-pieces-detachees {
  .items {
    max-width: 980px;
    @include flexbox;
    @include align-items(center);
    @include flex-flow(row wrap);
    @include justify-content(space-between);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;
    margin-bottom: 2rem;

    select {
      min-width: 200px;
    }
  }
}

@include breakpoint(680) {
  .page-pieces-detachees .items {
    display: block;
  }
}
// ==================================================== EO SEARCH

// ==================================================== DETAILS

.page-pieces-detachees--details {
  padding: 0 1rem;
  .pieces__content {
    margin: 1rem 0;

    .content__category {
      color: var(--color-1);
      font-weight: 600;
      font-size: 1.3rem;
    }

    .content__header {
      @include flexbox;
      @include align-items(center);
      @include flex-flow(row wrap);
      @include justify-content(space-between);
      gap: 0.5rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid var(--gray-3);

      .right {
        a {
          margin-bottom: 0.5rem;
        }
      }
    }

    .content__name {
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      font-size: clamp(1.5rem, 2vw, 2rem);
      line-height: 1;
    }

    .content__reference {
      font-size: 0.8rem;
      text-transform: uppercase;
    }

    .content__next-prev {
      @include flexbox;
      @include align-items(center);
      @include flex-flow(row wrap);
      @include justify-content(space-between);
      gap: 0.5rem;
    }

    .content__media {
      position: relative;
      margin: 1rem 0;
      border: 1px solid var(--gray-4);
      .content__zoom {
        position: absolute;
        right: 1rem;
        bottom: 0.5rem;

        i {
          font-size: 2rem;
          color: var(--color-2);
          margin: 0 4px;
        }
      }
    }

    .content__table {
      width: 100%;
      text-align: center;
      margin-top: 2rem;

      thead {
        background-color: var(--color-2);
        color: white;
        th {
          font-weight: normal;
          font-size: 0.9rem;
          padding: 0 5px;
          &:nth-child(9) {
            width: 100px;
          }
          &:nth-child(10) {
            width: 100px;
          }
        }
      }

      tbody {
        tr:nth-child(even) {
          background-color: var(--gray-2);
        }
        td {
          padding: 4px;
        }
      }

      .hikashop_product_quantity_div {
        @include justify-content(center);
      }

      .btn--add-cart {
        background-color: transparent;
        padding: 0;
        color: var(--color-2);
        font-size: 1.5rem;
        span {
          display: none;
        }
        &::before {
          @include icon("\f218");
        }
        &::after {
          content: none;
        }
      }
    }
  }
}

@include breakpoint(780) {
  .page-pieces-detachees--details .pieces__content .content__table {
    thead {
      display: none;
    }
    tbody {
      td::before {
        content: "";
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.8rem;
        color: var(--color-2f);
      }
      td:nth-child(1)::before {
        content: "Clé";
      }
      td:nth-child(2)::before {
        content: "Groupe";
      }
      td:nth-child(3)::before {
        content: "N° de pièce";
      }
      td:nth-child(4)::before {
        content: "Dispo";
      }
      td:nth-child(5)::before {
        content: "Description";
      }
      td:nth-child(6)::before {
        content: "Prix public";
      }
      td:nth-child(7)::before {
        content: "Remise";
      }
      td:nth-child(8)::before {
        content: "Prix net revendeur HT";
      }
      td:nth-child(9)::before {
        // content: "Qté";
      }
      td:nth-child(10)::before {
        // content: "Ajouter au panier";
      }
    }
    tr,
    td {
      display: block;
      text-align: center;
      padding: 6px;
      width: 100%;
    }
  }
}

// ==================================================== EO DETAILS
