.page-home .article {
  // display: none !important;
  // max-width: 1000px;
  // margin: 4rem auto;
  // padding: 5rem 0;
  // background-color: white;

  .blog__title {
    text-align: center;
    margin-bottom: calc(var(--gap) * 8);
  }

  .home-btn-actu {
    text-align: center;
    margin-top: 4rem;
  }
}

// ======================================================== LIST

.page-home .blog-featured {
  // display: grid;
  // grid-template-columns: repeat(auto-fit, 310px);
  // grid-auto-flow: dense;
  // grid-gap: 25px;
  // justify-content: center;
  //   ---------------------- item

  .items-row {
    position: relative;
    // @include shadow-1;
    // border-radius: 12px;
    overflow: hidden;
    // padding-bottom: 1rem;
    background-color: white;
    max-width: 800px;
    margin: 0 auto 2rem auto;
    padding: 0 1rem;
    border-radius: 7px;
  }

  .item {
    display: grid;
    grid-template-columns: 270px 1fr;
    border: 1px solid var(--gray-4);
  }
  //   ---------------------- icon modifier
  .icons {
    position: absolute;
    top: 0;
    left: 0;
  }
  //   ---------------------- image
  .item-image {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .item__content {
    padding: 1rem 0;
  }
  //   ---------------------- image
  .item-title {
    font-size: rem(24);
    padding: 0 1rem;
    color: var(--color-1);
    // font-weight: 500;
    margin: 0;
    text-transform: initial;
    text-align: left;

    &::after {
      content: none;
    }
  }
  //   ---------------------- texte
  p {
    padding: 7px 16px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

@include breakpoint(780) {
  .page-home .blog-featured .item {
    display: block;
  }
}

// ======================================================== EO LIST

// ======================================================== CARD
// .page-home .blog-featured {
//   display: grid;
//   grid-template-columns: repeat(auto-fit, 310px);
//   grid-auto-flow: dense;
//   grid-gap: 25px;
//   justify-content: center;
//   //   ---------------------- item
//   .items-row {
//     position: relative;
//     // @include shadow-1;
//     min-height: 450px;
//     // border-radius: 12px;
//     overflow: hidden;
//     padding-bottom: 1rem;
//     background-color: white;
//   }
//   //   ---------------------- icon modifier
//   .icons {
//     position: absolute;
//     top: 0;
//     left: 0;
//   }
//   //   ---------------------- image
//   .item-image {
//     width: 100%;
//     height: 200px;
//     margin-bottom: 1rem;
//     img {
//       width: 100%;
//       height: 100%;
//       object-fit: cover;
//       object-position: center;
//     }
//   }
//   //   ---------------------- image
//   .item-title {
//     font-size: rem(18);
//     padding: 0 1rem;
//     color: var(--color-1);
//     font-weight: 500;
//     margin: 0;
//   }
//   //   ---------------------- texte
//   p {
//     padding: 7px 16px;
//     display: -webkit-box;
//     overflow: hidden;
//     -webkit-line-clamp: 3;
//     -webkit-box-orient: vertical;
//   }
// }

// @include breakpoint(1080) {
//   .page-home .blog-featured {
//     grid-template-columns: 1fr;
//     padding: 0 1rem;
//   }
//   .page-home .blog-featured .item-image {
//     height: 300px;
//   }
// }

// @include breakpoint(550) {
//   .page-home .blog-featured .item-image {
//     height: 200px;
//   }
// }

// ======================================================== EO CARD
