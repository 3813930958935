::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: var(--color-1);
}

::-webkit-scrollbar-thumb {
  background: white;
  border: 3px solid var(--color-1);
  border-radius: 7px;
}
