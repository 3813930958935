.hkform-horizontal .hkcontrol-label {
}

.hkform-group {
  label.hkcontrol-label {
    text-align: left;
    padding: 0;
  }
  .hkc-sm-4,
  .hkc-sm-8 {
    width: 100%;
  }
}

.hika_options {
  dt {
    float: none !important;
    width: 100% !important;
    text-align: left !important;
  }

  dd {
    margin-left: 0 !important;
  }
}

// ------------------------------------------------ checkbox hikashop
.hikashop_filter_checkbox {
  display: inline-flex;
  align-items: center;
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: var(--color-2);
  position: relative;
  background-color: var(--gray-4);
  display: inline-flex;
  vertical-align: middle;
  border: 0;
  padding: 0 !important;
  box-shadow: none;
  width: 25px !important;
  min-width: 25px;
  height: 25px !important;
  cursor: pointer;

  &:checked {
    &::after {
      // content: "✔";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      line-height: 25px;
      text-align: center;
      width: 100%;
      font-size: 1.1rem;
      background-color: var(--color-1);
      color: white;
    }
  }
  &:hover {
  }
  &:focus {
    background-color: white;
  }
}

// ------------------------------------------------ eo checkbox hikashop

// ------------------------------------------------ checkbox & radio
.hikashop_filter_checkbox {
  display: flex;
  align-items: center;
}

// ----------------------- radio hikashop

.control-group .hkradio label {
  position: initial;
  height: initial;
  top: 0;
  left: 0;
  padding: 0 !important;
  margin: 0 !important;
  pointer-events: initial !important;
}
