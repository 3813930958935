// ------------------------------------------------------- popup notify

// modifier params plugins hikashop cart

.notifyjs-corner {
  left: 50% !important;
  transform: translateX(-50%);
}

.notifyjs-container {
  display: block !important;
  min-width: 300px;
}

.notifyjs-corner {
  text-align: center;
  .notifyjs-wrapper {
    animation: 0.15s scale;
  }

  @keyframes scale {
    0% {
      transform: scale(0, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  .notifyjs-metro-base,
  .notifyjs-metro-lite-base {
    min-height: auto;
  }

  .notifyjs-arrow {
  }

  .notifyjs-metro-base {
    @include inline-flex;
    @include align-items(center);
    @include justify-content(center);
    gap: 10px;
    color: var(--color-light) !important;
    cursor: default;
    border: none !important;
    border-radius: 7px;
    box-shadow: 10px 20px 41px rgba($color: #000000, $alpha: 0.4);
    padding: 1rem;
    .image,
    .title {
      display: none !important;
    }
    .image {
      @include flexbox;
      position: initial;
      transform: inherit;
      top: 0%;
      left: 0;
    }
    .title {
      display: none !important;
    }
    .text-wrapper {
      @include flexbox;
      @include align-items(center);
      gap: 10px;
      margin: 0 !important;
      // padding: 1rem 2rem;
      &::before {
        font-size: 2rem;
      }
    }
    .text {
      display: inline-flex;
      align-items: center;
      font-size: rem(22);
      font-weight: 500;
    }
    i {
      margin-right: rem(8);
      font-size: 2rem;
    }
  }
  .notifyjs-metro-base.notifyjs-metro-info {
    background-color: var(--color-2) !important;
    .text-wrapper::before {
      @include icon("\f058");
    }
  }

  .notifyjs-metro-base.notifyjs-metro-notice {
    background-color: var(--background-light);
    color: initial !important;
  }

  .notifyjs-metro-base.notifyjs-metro-warning,
  .notifyjs-metro-base.notifyjs-metro-error {
    background-color: var(--error) !important;
  }

  .text-wrapper::before {
    @include icon("\f06a");
  }
}

@include breakpoint(1180) {
  .notifyjs-container {
    min-width: 260px;
  }

  .notifyjs-corner .notifyjs-metro-base svg {
    height: 35px;
  }

  .notifyjs-corner .notifyjs-metro-base .text {
    font-size: 1rem;
  }
  .notifyjs-corner .notifyjs-metro-base {
    font-size: 1.7rem;
  }
}

@include breakpoint(550) {
  .notifyjs-corner .notifyjs-metro-base {
    padding: 0.5rem;
  }
  .notifyjs-corner {
    min-width: 90%;
    top: initial !important;
    bottom: 1rem !important;
  }
}
