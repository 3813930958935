// .hikabtn {
//   background-color: var(--color-2);
//   border: 0;
//   color: white;
//   border-radius: 3px !important;
//   &:hover {
//     background-color: var(--color-2);
//     color: white;
//   }
// }

.oList a:hover,
.oList a.sel {
  background-color: var(--color-1);
}

.hikabtn.hikabtn-primary {
  background-color: var(--color-1);
}

.hikabtn-mini {
  padding: 4px 8px !important;
  font-size: 0.9rem !important;
}

.btn--add-cart {
  &:hover {
    // background-color: rgba($color: var(--color-1), $alpha: 0.8);
    background-color: transparent;
    color: var(--color-1);
    // transition: all 100ms;
    // border: 1px solid var(--color-1);
  }
}

.btn--add-cart::after {
  // content: url("/images/cart.svg");
  // transform: scale(0.6);
  // @include icon("\f290");
  // font-size: 1.5rem;
}

@include breakpoint(1440) {
  .btn--add-cart::after {
    font-size: 1.2rem;
  }
}

@include breakpoint(1180) {
  .btn--add-cart::after {
    font-size: 1rem;
  }
}

@include breakpoint(550) {
  .btn--add-cart::after {
    font-size: 0.9rem;
  }
}
