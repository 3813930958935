.home-hero {
  position: relative;

  // ------------------------------------- MARQUES
  .hero__marques {
    text-align: center;
    margin: 1.5rem 0;
    padding: 0 1rem;

    .title {
      display: block;
      font-size: 1.2rem;

      color: var(--gray-5);
    }
  }
  // ------------------------------------- EO MARQUES

  .hero__content {
    position: absolute;
    z-index: 100;
    // background-color: rgba($color: var(--color-light), $alpha: 0.4);
    text-shadow: 0 6px 12px rgba($color: #000000, $alpha: 0.7);
    // ---------------------- 1
    // top: 50%;
    // left: 200px;
    // transform: translateY(-50%);
    // width: calc(100% - 300px);
    // max-width: 1200px;

    // ---------------------- EO 1
    // ---------------------- 2
    color: white;
    bottom: 2rem;
    font-weight: bold;

    padding-left: 2rem;
    // ---------------------- EO 2
  }

  .hero__title {
    display: block;
    font-size: clamp(1.2rem, 3vw, 3rem);
    font-family: var(--font-titre);
    // font-weight: 900;
    text-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.4);
    opacity: 0;
    // text-transform: uppercase;
    transform: translateY(50px);
    transition: transform 1s, opacity 1s;
  }
  .swiper-slide-active .hero__title {
    transition-delay: 0.5s;
    opacity: 1;
    transform: translateY(0);
  }

  .hero__description {
  }

  // ------------------------------------------------ MODELE 1

  .swiper-wrapper {
    width: 100%;
    height: 100vh;
    // margin-bottom: rem(30);
  }

  // ------------------------------------------------ EO MODELE 1

  // ------------------------------------------------ MODELE 2

  .swiper-wrapper {
    width: 100%;
    height: 550px;
    // margin-bottom: rem(30);
  }

  // ------------------------------------------------ EO MODELE 2

  .slide__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
    top: 0;
    left: 0;
    &::before {
      content: "";
      position: absolute;
      pointer-events: none;
      z-index: 100;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.2) 30%,
        rgba(0, 0, 0, 0.4) 100%
      );
    }
  }

  .swiper__slide {
    position: relative;
    z-index: 10;

    img {
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
    }
  }

  // ------------------------------------------------------------- navigation
  .swiper-button-prev,
  .swiper-button-next {
    color: white;
    font-weight: bold;
    transform: scale(1.1);

    &:hover {
      color: var(--color-1);
      // text-shadow: 0 15px 15px rgba($color: #000000, $alpha: 1);
    }
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 30px;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 30px;
  }

  // ------------------------------------------------------------- pagination

  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    // bottom: calc(30px - 8px);
  }

  .swiper-pagination-bullet {
    margin: 0 rem(10) !important;
    width: 16px;
    height: 16px;
    background-color: white;
    opacity: 0.6;
    // box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);

    &.swiper-pagination-bullet-active {
      width: 20px;
      height: 20px;
      background-color: var(--color-1);
      opacity: 1;
    }
  }
}

// ================================================================= RESPONSIVE

@include breakpoint(1700) {
}

@include breakpoint(1600) {
}

@include breakpoint(1440) {
  .home-hero .hero__content {
    // left: 100px;
    // width: calc(100% - 200px);
  }
}

@include breakpoint(1380) {
}

@include breakpoint(1180) {
}

@include breakpoint(980) {
}

@include breakpoint(780) {
  .home-hero .swiper-button-prev,
  .home-hero .swiper-button-next {
    // display: none;
  }
  .home-hero .hero__content {
    // left: 0;
    // width: calc(100% - 1rem);
    // padding: 0 0.5rem;
  }
}

@include breakpoint(550) {
  .home-hero .swiper-button-prev,
  .home-hero .swiper-container-rtl .swiper-button-next {
    left: 15px;
  }
  .home-hero .swiper-button-next,
  .home-hero .swiper-container-rtl .swiper-button-prev {
    right: 15px;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 2rem !important;
  }
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}

// ------------------------------------------------ MODELE 1
@include breakpoint(1600) {
  .home-hero .swiper-wrapper {
    height: 500px;
  }
}

@include breakpoint(1440) {
  .home-hero .swiper-wrapper {
    height: 450px;
  }
}

@include breakpoint(1380) {
  .home-hero .swiper-wrapper {
    height: 400px;
  }
}

@include breakpoint(1180) {
  .home-hero .swiper-wrapper {
    height: 350px;
  }
}

@include breakpoint(780) {
  .home-hero .swiper-wrapper {
    height: 300px;
  }
}

@include breakpoint(550) {
  .home-hero .swiper-wrapper {
    height: 250px;
  }
  .home-hero .hero__content {
    padding-left: 1rem;
    bottom: 1rem;
  }
}
// ------------------------------------------------ EO MODELE 1
