.page-114 #maincontent {
  // padding-top: 10rem;
  text-align: center;
}

// -------------------------------------------- FOX CONTACT

.fox-container {
  text-align: center;
  // background: white;
  // padding: 2rem 0 6rem 0;
}

// ------------------------ suppression des asterix
.required::after,
.asterisk::after {
  content: none !important;
}

// ------------------------ suppression des floats
.fox-item .controls {
  float: none !important;
}
// ------------------------ btn envoyer
.fox-item.fox-item-submit-fields .controls {
  width: 100% !important;

  .btn {
    margin-top: 1rem;
    margin-right: 0 !important;
    width: 100%;
    padding: rem(10) 0;
  }
}

// -------------------------------------------- MAP
#map {
  width: 100%;
  height: 500px;
}

/* ======================================================================  RESPONSIVE ============================= */

@include breakpoint(700) {
  #map {
    height: 300px;
  }
}
