// ---------------------------- hero

.all-hero {
  position: relative;
  img {
    min-height: 110px;
    object-fit: cover;
    object-position: center;
  }
  .title {
    position: absolute;
    display: block;
    color: white;
    z-index: 10;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-weight: bold;
    font-size: clamp(1.4rem, 3vw, 3rem);
    text-shadow: 0 10px 20px rgba($color: #000000, $alpha: 0.67);
  }
}

// ---------------------------- eo hero
