@charset 'utf-8';

@import "utils/functions";
@import "utils/mixins";
@import "utils/flexbox";
@import "utils/variables";
@import "utils/scrollbar";

@import "base/reset";
@import "base/responsive";
@import "base/typography";

@import "components/buttons";
@import "components/form";
@import "components/pagination";
@import "components/modal";
@import "components/message";
@import "components/breadcrumb";
@import "components/slide-all";
@import "components/loader";
@import "components/parallax";
@import "components/social_share";

@import "hikashop/template";

@import "layout/header";
@import "layout/layout";
@import "layout/footer";
@import "layout/grid";
@import "layout/navigation";

@import "pages/404";
@import "pages/sitemap";
@import "pages/rgpd";
@import "pages/home_1_hero";
@import "pages/home_2_presentation";
@import "pages/home_3_projet";
@import "pages/home_4_activite";
@import "pages/home_5_blog";
@import "pages/home_6_contact";
@import "pages/home_categories";
@import "pages/home_creations";
@import "pages/home_last_products";
@import "pages/home_meilleures_ventes";
@import "pages/home_promotions";
@import "pages/home_pieces_detachees";
@import "pages/blog";
@import "pages/contact";
@import "pages/hero_all";
@import "pages/pieces_detach";

@import "administration/dashboard";
@import "administration/header";
@import "administration/layout";
@import "administration/variables";
@import "administration/navigation";
@import "administration/navigation_top";
@import "administration/actualite";
@import "administration/profil";
@import "administration/hikamarket/layout";
@import "administration/hikamarket/caracteristiques";
@import "administration/hikamarket/categories";
@import "administration/hikamarket/clients";
@import "administration/hikamarket/commandes";
@import "administration/hikamarket/ma_page";
@import "administration/hikamarket/produits";
@import "administration/hikamarket/reductions";

@import "espace-client/login";
@import "espace-client/espace-client";
@import "espace-client/navigation";
@import "espace-client/dashboard";
@import "espace-client/commandes";
@import "espace-client/address";

@import "vendors/swiper-bundle.scss";

// ------ FONT SITE
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");
// ------ FONT ADMIN
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

// ---------------------------------------------- FONTAWESOME
// @import "vendors/fontawesome/fontawesome";
// @import "vendors/fontawesome/solid";
// @import "vendors/fontawesome/regular";
