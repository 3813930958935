.social__share {
  // background-color: var(--gray-6);
  border-radius: var(--radius);
  border: 1px solid var(--gray-6);
  font-size: 1rem;
  padding: 1em;
  .title {
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  ul {
    @include flexbox;
    @include align-items(center);
    @include flex-flow(row wrap);
    gap: 8px;
  }

  .btn--social {
    color: white;
    // padding: 1em !important;
    // height: 28px;
    width: auto;
    border-radius: 4px;
    padding: 2px 6px;
    @include flexbox;
    @include align-items(center);
    @include flex-flow(row wrap);
    gap: 4px;
    font-size: 0.85rem;
    svg {
      width: 16px;
      height: auto;
      fill: white;
    }
  }

  .btn--facebook {
    background-color: #1877f2;
  }

  .btn--twitter {
    background-color: rgb(29, 155, 240);
  }

  .btn--pinterest {
    background-color: #1d9bf0;
  }

  .btn--email {
    background-color: var(--color-2);
  }
}

@include breakpoint(780) {
  .social__share ul {
    @include justify-content(center);
  }
}
