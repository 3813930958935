// ------------------------------------ MODELE 2

.home-presentation {
  padding: var(--padding-section) 1rem;

  h2 {
  }

  .cards {
    @include flexbox;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    // grid-template-rows: 1fr 1fr;
    align-items: flex-start;
    gap: 15px;
  }

  .card {
    position: relative;
  }

  .card--contact {
    position: relative;

    a {
      display: block;
      &::before {
        content: "";
        position: absolute;
        z-index: 10;
        background-color: var(--color-1);
        opacity: 0.8;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
    img {
      display: block;
      width: 100%;
    }
    .card__title {
      position: absolute;
      z-index: 20;
      width: 100%;
      text-align: center;
      top: 50%;
      left: 0%;
      transform: translateY(-50%);
      color: white;
    }
  }

  .card__media {
    position: relative;
    overflow: hidden;
  }

  .card:hover .card__list {
    left: 0;
    transition: all 0.2s;
  }
  .card__list {
    position: absolute;
    width: 100%;
    height: 100%;
    @include flexbox;
    @include flex-flow(column wrap);
    @include justify-content(center);
    padding: 0.5rem 1rem;
    top: 0;
    left: -100%;
    background-color: rgba($color: #000000, $alpha: 0.75);
    color: white;

    li {
      margin: 0 1rem;
      padding: 4px 0;
      list-style-type: disc;
    }
  }

  img {
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .card__content {
    // position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    // background-color: var(--color-1);
  }

  .card__title,
  .card__desc {
    display: block;

    // color: white;
  }

  .card__title {
    font-weight: 500;
    font-size: rem(18);
    font-family: var(--font-titre);
  }
  .card__desc {
    font-size: 0.9rem;
  }
}

@include breakpoint(980) {
  .home-presentation .card__title {
    font-size: 1rem;
  }
  .home-presentation .card__desc {
    font-size: 0.8rem;
  }
  .home-presentation .card__list li {
    font-size: 0.8rem;
  }
}

@include breakpoint(780) {
  .home-presentation .cards {
    grid-template-columns: 1fr 1fr;
  }
}

@include breakpoint(550) {
  .home-presentation .cards {
    grid-template-columns: 1fr;
  }
}

@include breakpoint(440) {
}

@include breakpoint(360) {
}
