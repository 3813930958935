.hikashop_product {
  width: 275px !important;
  overflow: hidden;
  border-radius: 8px;
  padding: 0;
  transition: none;
  border: 1px solid var(--gray-4);
  .hikashop_container {
    margin: 0 !important;
  }

  .card {
    position: relative;
    height: 450px;
    background-color: white;
    padding: 2.5rem 12px 1rem 12px;
  }

  .card__category {
    // display: none;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 7px 0;
    background-color: var(--color-2);
    color: white;
    font-size: rem(13);
    padding: 2px 10px;
  }
  // ------------------------------------- image
  .card__img {
    margin-bottom: 1rem;
    height: initial !important;
    .hikashop_product_image_subdiv {
      width: 100% !important;
    }
    img {
      display: block;
    }
  }

  // ------------------------------------- eo image
  .card__name {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: left !important;
    font-size: rem(17);
    font-weight: bold;
    // max-height: 125px; pour firefox et autres sinon ne faire qu'un seule paragraphe
  }
  // ------------------------------------- référence
  .card__code {
    // display: none;
    display: block;
    text-transform: uppercase;
    color: var(--color-1);
    font-size: 0.8rem;
    text-align: left !important;
    &::before {
      content: "Référence";
    }
  }
  // ------------------------------------- eo référence
  .card__price__cart {
    position: absolute;
    width: 100%;
    bottom: 5px;
    right: 0;
    padding: 0 12px;
    text-align: right;
    // @include flexbox;
    // @include align-items(center);
    // @include justify-content(space-between);
  }
  .card__cart {
    @include flexbox;
    @include align-items(center);
    // ---- texte ajouter au panier
    button span {
      display: none;
    }
    .hikashop_product_stock_count {
      position: absolute;
      left: 0;
      bottom: -5px;
      font-size: 0.7rem;
      width: 100%;
      text-align: center !important;
      color: white;
      padding: 2px 0;
      background-color: var(--color-1);
      display: none;
    }
  }
  .card__price {
    font-size: rem(26);
    font-weight: bold;

    .hikashop_product_price_per_unit {
      display: none;
    }
    .hikashop_product_discount_amount {
      display: none;
    }
    // --------------------------------------------------- réduction
    .hikashop_product_price_before_discount {
      text-decoration: line-through;
      font-size: 1rem;
      margin-right: 7px;
      color: var(--color-2);
    }
    // --------------------------------------------------- eo réduction
  }
  .btn--add-cart {
    width: 46px;
    height: 46px;
    padding: 0;
    border-radius: 50%;
  }
  .btn--waitlist {
    display: none !important;
  }
}

@include breakpoint(1440) {
  .hikashop_product {
    width: 250px !important;

    .card {
      height: 420px;
      padding: 2rem 10px 1rem 10px;
    }
    .card__category {
      font-size: 0.75rem;
      padding: 2px 8px;
    }
    .card__name {
      font-size: 0.9rem;
    }
    .card__price {
      font-size: 1.2rem;
    }
    .btn--add-cart {
      width: 40px;
      height: 40px;
    }
  }
}

@include breakpoint(1180) {
  .hikashop_product {
    width: 225px !important;

    .card {
      height: 390px;
    }
    .card__name {
      font-size: 0.85rem;
    }
    .card__price {
      font-size: 1.1rem;
    }
    .btn--add-cart {
      width: 38px;
      height: 38px;
    }
  }
}

@include breakpoint(550) {
  .hikashop_product {
    width: 170px !important;

    .card {
      height: 320px;
      padding: 2rem 10px 1rem 10px;
    }

    .card__category {
      font-size: 0.7rem;
    }
    .card__name {
      font-size: 0.8rem;
    }
    .card__price__cart {
      padding: 0 10px;
    }
    .card__price {
      font-size: 0.95rem;

      .hikashop_product_price_before_discount {
        font-size: 0.8rem;
      }
    }
    .btn--add-cart {
      width: 32px;
      height: 32px;
    }
  }
}

@include breakpoint(380) {
  .hikashop_product {
    width: 158px !important;

    .card {
      height: 310px;
    }
    .card__category {
      font-size: 0.65rem;
    }
    .card__name {
      font-size: 0.75rem;
    }
  }
}

@include breakpoint(359) {
  .hikashop_product {
    width: 100% !important;

    .card {
      height: initial;
      padding-bottom: 3rem;
    }
    .hikashop_product_image_subdiv {
      text-align: center;
    }
  }
}
