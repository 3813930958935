.parallax {
  position: relative;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 650px;
  max-height: 1200px;
  @include flexbox;
  @include flex-flow(column wrap);
  @include justify-content(center);
  @include align-content(center);
  text-align: center;
  &::before {
    background: rgb(199, 105, 189);
    background: linear-gradient(
      90deg,
      rgba(199, 105, 189, 0.835171568627451) 0%,
      rgba(227, 222, 245, 0.5998774509803921) 100%
    );
    bottom: 0;
    content: "";
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
  }
  //   si pas de contenu intégrer le padding
  .parallax__prop {
    // padding-top: 60%;
  }
  .content {
    position: relative;
    z-index: 1;
    color: white;
    max-width: 980px;
    padding: 0 1rem;
    align-items: center;
    p {
      font-weight: bold;
      font-size: rem(40);
    }
  }
}

@include breakpoint(768) {
  .parallax {
    background-attachment: scroll;
  }
  .home__promotions .content p {
    font-size: 1.7rem;
  }
}

@include breakpoint(550) {
  .parallax {
    height: 450px;
  }
  .home__promotions .content p {
    font-size: 1.3rem;
    font-weight: 600;
  }
}
