// ========================================= BOUTON RETOUR
.page-administration {
  a.hikabtn[href*="/vendor"] {
    display: none !important;
  }
}

.hikamarket_section_container {
  border: 1px solid var(--color-1);
  padding: 1rem;
  border-radius: 7px;
  background-color: white;
}

// ========================================= SEARCH

.hikamarket_search_block {
  margin-bottom: 1rem;
  display: unset !important;
  .hk-input-group {
    @include flexbox;
    @include align-items(flex-start);
    margin-bottom: 0;
    // gap: 25px;
  }
  .hikabtn {
    height: 60px !important;
    margin-left: 0;
    min-width: 60px;
    background-color: var(--color-2);
    color: white;
    font-size: 1.4rem;
    &:last-child {
      margin-left: 0.5rem !important;
    }
  }
}

// ========================================= EO SEARCH

// --------------------------------------------- STATUS

.order-label {
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  padding: 2px 7px;
  font-size: 0.8rem;
  //   text-transform: uppercase;
  border-radius: 50px;
}
.order-label-created {
  border-color: var(--success);
  color: var(--success);
}

.order-label-confirmed {
  border-color: var(--success);
  background-color: var(--success);
  color: white;
}

.order-label-cancelled,
.order-label-refunded {
  border-color: var(--error);
  background-color: var(--error);
  color: white;
}

.order-label-shipped {
  border-color: #ffe900;
  background-color: #ffe900;
  color: #7a7a7a;
}

.order-label-pending {
  border-color: var(--warning);
  background-color: var(--warning);
  color: white;
}

// --------------------------------------------- EO STATUS

//   ------------------------------------------ catégorie

.namebox {
  @include inline-flex;
  @include align-items(center);
  background-color: white;
  padding: 4px 8px;
  margin: 0 0.5rem 0.5rem 0;

  a {
    span {
      display: none;
    }
    &::before {
      @include icon("\f410");
      color: var(--color-1);
      font-size: 1.3rem;
      margin-left: 4px;
    }
  }
}

//   ------------------------------------------ eo catégorie

// -------------------- table
.page-administration {
  .hikam_table,
  .table,
  .hikamarket_stat_table {
    width: 100%;
    thead th {
      padding: 15px 6px;
    }
    tbody tr:nth-child(even) {
      background-color: var(--gray-2);
      padding: 6px;
    }
    tbody td {
      padding: 12px 6px;
    }
    .hikam_column_move {
    }
    // ---- bouton édition / move
    .fa-arrows-alt-v,
    .fa-pencil-alt {
      width: 25px;
      text-align: center;
      margin-right: 7px !important;
      background-color: var(--color-1);
      color: white;
      padding: 6px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
// -------------------- eo table

.hkc-md-12 {
  padding: 0;
}

.hikam_sort_zone {
  // display: none !important;
}

.expand-filters {
  margin: 1rem 0 2rem 0;
}

@include breakpoint(1368) {
  .hikam_table {
    thead th {
      padding: 10px 6px;
    }
    tbody tr:nth-child(odd) {
      background-color: var(--gray-6);
      padding: 6px;
    }
    tbody td {
      padding: 6px;
      font-size: 0.85rem;
    }
  }
}

@include breakpoint(880) {
  .hikam_table {
    thead th {
      display: none;
    }
    tbody td {
      display: block;
      text-align: center;
      padding: 6px;
      font-size: 0.85rem;
      &::before {
        content: "";
        display: block;
        font-weight: bold;
        margin: auto;
      }
    }
  }
}

@include breakpoint(550) {
  .hikamarket_search_block .hikabtn {
    height: 50px !important;
    font-size: 1.2rem;
    min-width: 40px;
  }
  .hikamarket_search_block .hikabtn:last-child {
    margin-left: 0.2rem !important;
  }
}
