.breadcrumb__wrapper {
  position: relative;
  // margin: 0 auto 1rem auto;

  z-index: 10;
  background-color: var(--color-1);
  margin-bottom: 2rem;

  .breadcrumb {
    max-width: var(--container);
    margin: auto;
    @include flexbox;
    @include align-items(center);
    @include flex-flow(row wrap);
    padding: 1rem;

    //   ---------------------------- lien en cours
    li.active {
      // display: none;
    }

    //   ---------------------------- eo lien en cours

    //   ---------------------------- arrow
    .divider {
      margin: 0 rem(5);
      img,
      &.icon-location {
        display: none;
      }
      &::before {
        @include icon("\f30b");
        color: var(--color-1);
      }
    }
    //   ---------------------------- eo arrow

    li {
      text-transform: lowercase;
    }

    .pathway:hover {
      color: var(--color-1);
    }

    .active {
      font-weight: bold;
    }
  }
}

@include breakpoint(980) {
  .breadcrumb__wrapper .breadcrumb {
    padding: 1rem 0.5rem;
  }
  .breadcrumb__wrapper .breadcrumb li {
    font-size: 0.9rem;
  }
}

@include breakpoint(550) {
  .breadcrumb__wrapper .breadcrumb {
    padding: 0.5rem;
  }
  .breadcrumb__wrapper .breadcrumb li {
    font-size: 0.8rem;
  }
}
